import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Mutăm array-urile de statusuri în afara componentei, ca să scăpăm de warning-uri:
const CHECKED_IN_STATUSES = [
  'checked in',
  'paid with cash',
  'paid with card',
  'check in cu plata online'
];

// Toate statusurile disponibile pentru filtrare
const ALL_STATUSES = [
  {value: '', label: 'Caută după status'},  // Empty option with placeholder text
  {value: 'paid with cash', label: 'Checked in - plată cash'},
  {value: 'paid with card', label: 'Checked in - plată card'},
  {value: 'check in cu plata online', label: 'Checked in - plată online'},
  {value: 'pending', label: 'În așteptare'},
  {value: 'checked out', label: 'Checked out'}
];

// Constants
const API_BASE_URL = 'https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api';

function App() {
  // ------------------- State-uri de autentificare -------------------
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [authError, setAuthError] = useState('');

  // Parola standard
  const SECRET_KEY = 'P@rKaDo';
  // Parola pentru acces avansat (care afișează și taburile de încasări și butonul "Trimite Factura")
  const ADVANCED_PASSWORD = 'niC4Parkado';

  // Vom folosi acest state pentru a decide dacă afișăm taburile de încasări și butonul "Trimite Factura"
  const [showEarningsTabs, setShowEarningsTabs] = useState(false);

  // ------------------- Bookings State -------------------
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sendingEmailId, setSendingEmailId] = useState(null);
  const [hidingBookingId, setHidingBookingId] = useState(null);
  const [editingBooking, setEditingBooking] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    carPlate: ''
  });
  
  // ------------------- Referral State -------------------
  const [referralAgencies, setReferralAgencies] = useState([]);
  const [referralBookings, setReferralBookings] = useState({});
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [isLoadingReferral, setIsLoadingReferral] = useState(false);
  const [isLoadingReferralAgencies, setIsLoadingReferralAgencies] = useState(false);
  const [referralError, setReferralError] = useState(null);
  const [user, setUser] = useState({isAdmin: true}); // Default to admin user for referral access

  // New filter states
  const [nameFilter, setNameFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState('');
  const [carPlateFilter, setCarPlateFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  // Sorting states
  const [sortField, setSortField] = useState('id');
  const [sortDirection, setSortDirection] = useState('desc');

  const [activeTab, setActiveTab] = useState('calendar');
  const [newBooking, setNewBooking] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    carPlate: '',
    startDate: null,
    endDate: null,
    totalPrice: '',
    status: 'Pending'
  });

  // ------------------- State pentru agregări lunare -------------------
  const [monthlyData, setMonthlyData] = useState([]);
  const [monthlyCashData, setMonthlyCashData] = useState([]);
  const [monthlyNetopiaData, setMonthlyNetopiaData] = useState([]);
  const [monthlyNetopiaAccountData, setMonthlyNetopiaAccountData] = useState([]);

  const [invoiceProcessing, setInvoiceProcessing] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      fetchBookings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // Load referral data when the user switches to the referral tab
  useEffect(() => {
    if (activeTab === 'referral' && isAuthenticated) {
      fetchReferralAgencies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, isAuthenticated]);

  // ------------------- Fetch Bookings -------------------
  const fetchBookings = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/dashboardbookings');
      // Filtrăm bookingurile pentru a exclude cele ascunse (IsHidden = 1)
      const visibleBookings = response.data.filter(booking => !booking.isHidden);
      setBookings(visibleBookings);
    } catch (error) {
      console.error('Error fetching bookings:', error);
      setError('Failed to fetch bookings. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // ------------------- Creare Booking -------------------
  const handleCreateBooking = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings', newBooking);
      const bookingId = response.data.id;
      const email = newBooking.email;
      const emailRequest = { email: email, bookingId: bookingId };
      try {
        await axios.post('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/send-reservation-email', emailRequest);
      } catch (emailError) {
        console.error('Error sending reservation email:', emailError);
      }
      fetchBookings();
      setActiveTab('calendar');
      setNewBooking({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        carPlate: '',
        startDate: null,
        endDate: null,
        totalPrice: '',
        status: 'Pending'
      });
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

  // ------------------- Calendar Filtrare Bookings -------------------
  const filteredBookings = bookings.filter(booking => {
    // Date filter
    if (startDate && endDate) {
      const bookingStartDate = new Date(booking.startDate);
      const bookingEndDate = new Date(booking.endDate);
      if (!(bookingStartDate >= startDate && bookingEndDate <= endDate)) {
        return false;
      }
    }
    
    // Name filter (searches in both firstName and lastName)
    if (nameFilter && nameFilter.trim() !== '') {
      const fullName = `${booking.firstName || ''} ${booking.lastName || ''}`.toLowerCase().replace(/\s+/g, '');
      if (!fullName.includes(nameFilter.toLowerCase().replace(/\s+/g, ''))) {
        return false;
      }
    }
    
    // Phone filter
    if (phoneFilter && phoneFilter.trim() !== '') {
      const phone = (booking.phone || '').toLowerCase().replace(/\s+/g, '');
      if (!phone.includes(phoneFilter.toLowerCase().replace(/\s+/g, ''))) {
        return false;
      }
    }
    
    // Car plate filter
    if (carPlateFilter && carPlateFilter.trim() !== '') {
      const carPlate = (booking.carPlate || '').toLowerCase().replace(/\s+/g, '');
      if (!carPlate.includes(carPlateFilter.toLowerCase().replace(/\s+/g, ''))) {
        return false;
      }
    }
    
    // Status filter
    if (statusFilter && statusFilter.trim() !== '') {
      const status = (booking.status || '').toLowerCase();
      const searchStatus = statusFilter.toLowerCase();
      if (status !== searchStatus) {
        return false;
      }
    }
    
    return true;
  }).sort((a, b) => {
    // Sort by the selected field
    if (sortField === 'id') {
      return sortDirection === 'asc' 
        ? a.id - b.id 
        : b.id - a.id;
    } else if (sortField === 'startDate') {
      const dateA = new Date(a.startDate || 0);
      const dateB = new Date(b.startDate || 0);
      return sortDirection === 'asc' 
        ? dateA - dateB 
        : dateB - dateA;
    } else if (sortField === 'endDate') {
      const dateA = new Date(a.endDate || 0);
      const dateB = new Date(b.endDate || 0);
      return sortDirection === 'asc' 
        ? dateA - dateB 
        : dateB - dateA;
    }
    return 0;
  });

  const handleSort = (field) => {
    // If clicking the same field, toggle direction
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // If clicking a new field, set it as the sort field and default to ascending
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleClearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setNameFilter('');
    setPhoneFilter('');
    setCarPlateFilter('');
    setStatusFilter('');
    // Reset sorting to default (ID descending)
    setSortField('id');
    setSortDirection('desc');
  };

  // ------------------- Stil pt status -------------------
  const getStatusStyle = (status) => {
    let backgroundColor;
    let color = 'white';
    switch (status?.toLowerCase()) {
      case 'paid with cash':
        backgroundColor = '#4CAF50';
        break;
      case 'pending':
        backgroundColor = '#ff9800';
        break;
      case 'cancelled':
        backgroundColor = '#f44336';
        break;
      default:
        backgroundColor = '#808080';
    }
    return {
      backgroundColor,
      color,
      padding: '4px 8px',
      borderRadius: '4px',
      display: 'inline-block',
      fontSize: '0.875rem'
    };
  };

  // ------------------- Trimitere Email -------------------
  const handleSendEmail = async (booking) => {
    const confirmSend = window.confirm(`Are you sure you want to send an email to ${booking.email}?`);
    if (!confirmSend) return;
    if (!booking.email) return;
    try {
      setSendingEmailId(booking.id);
      const emailRequest = { email: booking.email, bookingId: booking.id };
      await axios.post('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/send-reservation-email', emailRequest);
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      setSendingEmailId(null);
    }
  };

  // ------------------- Ascundere Booking -------------------
  const handleHideBooking = async (bookingId) => {
    const confirmHide = window.confirm(`Sigur doriți să anulați rezervarea cu ID-ul ${bookingId}?`);
    if (!confirmHide) return;
    
    try {
      setHidingBookingId(bookingId);
      await axios.put(`https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/${bookingId}/hide`);
      
      // Actualizăm lista de bookings după ascundere
      setBookings(prevBookings => prevBookings.filter(booking => booking.id !== bookingId));
      
    } catch (error) {
      console.error('Error hiding booking:', error);
    } finally {
      setHidingBookingId(null);
    }
  };

  // ------------------- Editare Booking -------------------
  const handleEditClick = (booking) => {
    setEditingBooking(booking);
    setEditFormData({
      firstName: booking.firstName || '',
      lastName: booking.lastName || '',
      email: booking.email || '',
      phone: booking.phone || '',
      carPlate: booking.carPlate || ''
    });
    setIsEditModalOpen(true);
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Construim obiectul pentru cererea PATCH, incluzând doar câmpurile care au fost modificate
      const updateData = {};
      
      if (editFormData.firstName !== editingBooking.firstName) {
        updateData.firstName = editFormData.firstName;
      }
      
      if (editFormData.lastName !== editingBooking.lastName) {
        updateData.lastName = editFormData.lastName;
      }
      
      if (editFormData.email !== editingBooking.email) {
        updateData.email = editFormData.email;
      }
      
      if (editFormData.phone !== editingBooking.phone) {
        updateData.phone = editFormData.phone;
      }
      
      if (editFormData.carPlate !== editingBooking.carPlate) {
        updateData.carPlate = editFormData.carPlate;
      }
      
      // Verificăm dacă există modificări
      if (Object.keys(updateData).length === 0) {
        setIsEditModalOpen(false);
        return;
      }
      
      // Trimitem cererea PATCH
      await axios.patch(`https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/${editingBooking.id}`, updateData);
      
      // Actualizăm lista de bookings
      setBookings(prevBookings => 
        prevBookings.map(booking => 
          booking.id === editingBooking.id 
            ? { ...booking, ...editFormData } 
            : booking
        )
      );
      
      // Închidem modalul
      setIsEditModalOpen(false);
      
    } catch (error) {
      console.error('Error updating booking:', error);
    }
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setEditingBooking(null);
  };

  // ------------------- Trimitere Factura (doar pentru parola avansată) -------------------
  const handleSendInvoice = async (bookingId) => {
    if (invoiceProcessing) return; // Nu permite procesarea dacă deja se procesează
    setInvoiceProcessing(true);
    try {
      // 1. Preluăm detaliile bookingului pentru a construi payload-ul
      const bookingRes = await axios.get(`https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/${bookingId}`);
      const bookingData = bookingRes.data;

      // 2. Construim payload-ul pentru emiterea facturii, folosind VAT-ul fix "45598924"
      const invoiceRequest = {
        companyVatCode: "RO42723445", // VAT fix
        seriesName: "TTR",         // valoare inițială; se va actualiza după răspuns
        client: {
          name: bookingData.isCompany
            ? bookingData.denumire
            : `${bookingData.firstName} ${bookingData.lastName}`,
          vatCode: bookingData.isCompany ? bookingData.cui : "0000000000000",
          isTaxPayer: bookingData.isCompany ? bookingData.platitorTVA : false,
          address: bookingData.address,
          city: bookingData.city,
          county: bookingData.county,
          country: bookingData.country,
          email: bookingData.email,
          saveToDb: true,
          bookingID: bookingData.id
        },
        issueDate: new Date().toISOString(),
        products: [
          {
            code: "1",
            name: `Parcare aeroport - ${bookingData.carPlate} - ${new Date(bookingData.startDate).toLocaleString()} - ${new Date(bookingData.endDate).toLocaleString()} - Serviciu Parcare aeroport Parkado (parkado.ro)`,
            measuringUnitName: "buc",
            currency: "Ron",
            quantity: 1,
            price: bookingData.totalPrice,
            isTaxIncluded: true,
            taxPercentage: 19,
            saveToDb: false
          }
        ]
      };

      // 3. Emit factura și preluăm detaliile din răspuns (ex: number și series)
      const invoiceResponse = await axios.post(
        "https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/SmartBill/create-invoice",
        invoiceRequest
      );
      const invoiceDetails = invoiceResponse.data;
      console.log("Factura a fost creată:", invoiceDetails);

      // 4. Actualizăm doar bookingul respectiv în state
      setBookings(prevBookings =>
        prevBookings.map(booking =>
          booking.id === bookingId
            ? {
              ...booking,
              button: 'download',                    // Modificăm butonul la "download"
              responseNumber: invoiceDetails.number,   // Setăm numărul facturii din răspuns
              seriesName: invoiceDetails.series,       // Setăm seria facturii din răspuns
              companyVatCode: "RO42723445"               // VAT-ul fix
            }
            : booking
        )
      );

      // Nu mai facem GET pentru a reîncărca toate bookingurile,
      // deoarece actualizăm doar bookingul afectat în state.
    } catch (error) {
      console.error("Eroare la emiterea facturii:", error);
    } finally {
      setInvoiceProcessing(false);
    }
  };


  // ------------------- Funcție pentru descărcarea facturii -------------------
  const handleDownloadInvoice = async (booking) => {
    if (invoiceProcessing) return; // Nu permite dacă deja se procesează
    setInvoiceProcessing(true);
    try {
      const response = await axios.get(
        `https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/Smartbill/invoice-file/${booking.companyVatCode}/${booking.seriesName}/${booking.responseNumber}`,
        { responseType: 'blob' }
      );
      // Creăm un URL temporar pentru blob
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      // Creăm un link și simulăm un click pentru descărcare
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `invoice_${booking.seriesName}_${booking.responseNumber}_${new Date().toISOString().slice(0, 10)}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Eroare la descărcarea facturii:", error);
    } finally {
      setInvoiceProcessing(false);
    }
  };

  // ------------------- Nr de Booking-uri Relevante (ex. statistici) -------------------
  const checkedInCount = bookings.filter(booking => {
    const status = booking.status?.toLowerCase() || '';
    const isRelevantStatus = CHECKED_IN_STATUSES.includes(status);
    if (!isRelevantStatus) return false;
    if (!booking.startDate || !booking.endDate) return false;
    const start = new Date(booking.startDate);
    const end = new Date(booking.endDate);
    const now = new Date();
    const threeDaysAgo = new Date(now.getTime() - 3 * 24 * 60 * 60 * 1000);
    const threeDaysLater = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000);
    return start <= threeDaysLater && end >= threeDaysAgo;
  }).length;

  const renderStatistics = () => (
    <div style={{
      padding: '0.75rem 1.5rem',
      backgroundColor: '#e7feea',
      border: '1px solid #000000',
      borderRadius: '4px',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '0.5rem',
      width: 'auto'
    }}>
      <span style={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>
        Checked In la ora actuală: <b>{checkedInCount}</b>
      </span>
    </div>
  );

  // ------------------- VIEW: Calendar -------------------
  const renderCalendarView = () => (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem' }}>
        {/* Date filters row */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
            style={{ flex: 1, padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
          />
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
            style={{ flex: 1, padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
          />
        </div>
        
        {/* Text filters row */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <input
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            placeholder="Caută după nume/prenume"
            style={{ flex: 1, padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
          />
          <input
            type="text"
            value={phoneFilter}
            onChange={(e) => setPhoneFilter(e.target.value)}
            placeholder="Caută după telefon"
            style={{ flex: 1, padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
          />
          <input
            type="text"
            value={carPlateFilter}
            onChange={(e) => setCarPlateFilter(e.target.value)}
            placeholder="Caută după matriculare"
            style={{ flex: 1, padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
          />
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            style={{ flex: 1, padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
          >
            {ALL_STATUSES.map(status => (
              <option key={status.value} value={status.value}>
                {status.label}
              </option>
            ))}
          </select>
        </div>
        
        {/* Actions row */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <button
            onClick={handleClearFilter}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#f44336',
              color: 'white',
              border: 'none',
              borderRadius: '4px'
            }}
          >
            Anulează Filtrul
          </button>
          
          {renderStatistics()}
          
          {/* Sort indicator */}
          {(sortField !== 'id' || sortDirection !== 'desc') && (
            <div style={{ 
              padding: '0.5rem 1rem', 
              backgroundColor: '#fff3cd', 
              borderRadius: '4px',
              border: '1px solid #ffeeba',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }}>
              <span>
                Ordonat după: <strong>
                  {sortField === 'id' ? 'ID' : 
                   sortField === 'startDate' ? 'Data început' : 
                   sortField === 'endDate' ? 'Data sfârșit' : sortField}
                </strong> ({sortDirection === 'asc' ? 'crescător' : 'descrescător'})
              </span>
              <button 
                onClick={() => {
                  setSortField('id');
                  setSortDirection('desc');
                }}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: '#856404',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  padding: '0 0.25rem'
                }}
              >
                ×
              </button>
            </div>
          )}
          
          <div style={{ 
            marginLeft: 'auto',
            padding: '0.5rem 1rem', 
            backgroundColor: '#e3f2fd', 
            borderRadius: '4px',
            border: '1px solid #90caf9'
          }}>
            Rezultate: <strong>{filteredBookings.length}</strong> din {bookings.length}
          </div>
        </div>
      </div>

      <div style={{ overflowX: 'auto' }}>
        <table style={{ minWidth: '100%', borderCollapse: 'collapse', backgroundColor: 'white', border: '1px solid #ddd' }}>
          <thead style={{ backgroundColor: '#f2f2f2' }}>
            <tr>
              <th 
                style={{ 
                  padding: '0.5rem', 
                  borderBottom: '1px solid #ddd',
                  cursor: 'pointer',
                  position: 'relative',
                  backgroundColor: sortField === 'id' ? '#e3f2fd' : 'inherit',
                  userSelect: 'none'
                }}
                onClick={() => handleSort('id')}
              >
                ID {sortField === 'id' && (
                  <span style={{ 
                    marginLeft: '5px',
                    fontSize: '0.8rem'
                  }}>
                    {sortDirection === 'asc' ? '▲' : '▼'}
                  </span>
                )}
              </th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Nume</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Prenume</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Email</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Telefon</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Matriculare</th>
              <th 
                style={{ 
                  padding: '0.5rem', 
                  borderBottom: '1px solid #ddd',
                  cursor: 'pointer',
                  position: 'relative',
                  backgroundColor: sortField === 'startDate' ? '#e3f2fd' : 'inherit',
                  userSelect: 'none'
                }}
                onClick={() => handleSort('startDate')}
              >
                Data început {sortField === 'startDate' && (
                  <span style={{ 
                    marginLeft: '5px',
                    fontSize: '0.8rem'
                  }}>
                    {sortDirection === 'asc' ? '▲' : '▼'}
                  </span>
                )}
              </th>
              <th 
                style={{ 
                  padding: '0.5rem', 
                  borderBottom: '1px solid #ddd',
                  cursor: 'pointer',
                  position: 'relative',
                  backgroundColor: sortField === 'endDate' ? '#e3f2fd' : 'inherit',
                  userSelect: 'none'
                }}
                onClick={() => handleSort('endDate')}
              >
                Data sfârșit {sortField === 'endDate' && (
                  <span style={{ 
                    marginLeft: '5px',
                    fontSize: '0.8rem'
                  }}>
                    {sortDirection === 'asc' ? '▲' : '▼'}
                  </span>
                )}
              </th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Preț</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Status</th>
              {showEarningsTabs && (
                <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Factura Emisa</th>
              )}
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Acțiuni</th>
            </tr>
          </thead>
          <tbody>
            {filteredBookings.map((booking) => (
              <tr key={booking.id}>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.id}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.firstName || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.lastName || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.email || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.phone || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.carPlate || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                  {booking.startDate ? new Date(booking.startDate).toLocaleString() : 'N/A'}
                </td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                  {booking.endDate ? new Date(booking.endDate).toLocaleString() : 'N/A'}
                </td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                  {typeof booking.totalPrice === 'number' ? booking.totalPrice.toFixed(2) : 'N/A'}
                </td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                  <span style={getStatusStyle(booking.status)}>
                    {booking.status || 'N/A'}
                  </span>
                </td>
                {showEarningsTabs && (
                  <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                    <div style={{
                      width: '16px',
                      height: '16px',
                      border: '1px solid green',
                      borderRadius: '3px',
                      backgroundColor: booking.button === "download" ? 'green' : 'transparent',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      {booking.button === "download" && (
                        <span style={{ color: 'white', fontSize: '14px', lineHeight: 0 }}>✓</span>
                      )}
                    </div>
                  </td>
                )}
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                  <button
                    onClick={() => handleSendEmail(booking)}
                    style={{
                      padding: '0.5rem 1rem',
                      backgroundColor: '#008CBA',
                      color: 'white',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                    disabled={sendingEmailId === booking.id}
                  >
                    {sendingEmailId === booking.id ? 'Se trimite...' : 'Trimite Email'}
                  </button>

                  {/* Dacă valoarea câmpului Button este "Upload", afișăm butonul de creare factură */}
                  {booking.button === "upload" && showEarningsTabs && (
                    <button
                      onClick={() => handleSendInvoice(booking.id)}
                      disabled={invoiceProcessing}
                      style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: invoiceProcessing ? "#ccc" : "#009688",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: invoiceProcessing ? "not-allowed" : "pointer",
                        display: "flex",
                        marginTop: "0.5rem",
                      }}
                    >
                      {invoiceProcessing ? "Se procesează..." : "Crează Factură"}
                    </button>
                  )}

                  {/* Dacă valoarea câmpului Button este "download", afișăm butonul de descărcare factură */}
                  {booking.button === "download" && showEarningsTabs && (
                    <button
                      onClick={() => handleDownloadInvoice(booking)}
                      disabled={invoiceProcessing}
                      style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: invoiceProcessing ? "#ccc" : "#9C27B0",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: invoiceProcessing ? "not-allowed" : "pointer",
                        display: "flex",
                        marginTop: "0.5rem",
                      }}
                    >
                      {invoiceProcessing ? "Se procesează..." : "Descarcă Factură"}
                    </button>
                  )}

                  {/* Buton pentru editarea rezervării */}
                  {showEarningsTabs && (
                    <button
                      onClick={() => handleEditClick(booking)}
                      style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: "#2196F3",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        display: "flex",
                        marginTop: "0.5rem",
                      }}
                    >
                      Editează Rezervare
                    </button>
                  )}

                  {/* Buton pentru ascunderea rezervării */}
                  {showEarningsTabs && (
                    <button
                      onClick={() => handleHideBooking(booking.id)}
                      disabled={hidingBookingId === booking.id}
                      style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: hidingBookingId === booking.id ? "#ccc" : "#f44336",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: hidingBookingId === booking.id ? "not-allowed" : "pointer",
                        display: "flex",
                        marginTop: "0.5rem",
                      }}
                    >
                      {hidingBookingId === booking.id ? "Se anuleaza..." : "Anulează Rezervare"}
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );

  // ------------------- Calcul preț total la crearea unei comenzi noi -------------------
  const calculateTotalPrice = (startDate, endDate) => {
    if (!startDate || !endDate) return 0;
    const start = new Date(startDate);
    const end = new Date(endDate);
    const totalDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    let perDayPrice = 0;
    if (totalDays >= 23) {
      perDayPrice = 15;
    } else if (totalDays >= 14 && totalDays <= 22) {
      perDayPrice = 20;
    } else if (totalDays >= 7 && totalDays <= 13) {
      perDayPrice = 20;
    } else if (totalDays >= 4 && totalDays <= 6) {
      perDayPrice = 25;
    } else if (totalDays === 3) {
      perDayPrice = 30;
    } else if (totalDays === 2) {
      perDayPrice = 40;
    } else if (totalDays === 1) {
      perDayPrice = 40;
    }
    return perDayPrice * totalDays;
  };

  useEffect(() => {
    if (newBooking.startDate && newBooking.endDate) {
      const price = calculateTotalPrice(newBooking.startDate, newBooking.endDate);
      setNewBooking(prevBooking => ({
        ...prevBooking,
        totalPrice: price
      }));
    } else {
      setNewBooking(prevBooking => ({
        ...prevBooking,
        totalPrice: ''
      }));
    }
  }, [newBooking.startDate, newBooking.endDate]);

  // ------------------- VIEW: Adaugă Comandă -------------------
  const renderAddOrderForm = () => (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <form onSubmit={handleCreateBooking} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ flex: 1 }}>
            <label style={{ display: 'block', marginBottom: '0.5rem' }}>Nume</label>
            <input
              type="text"
              value={newBooking.firstName}
              onChange={(e) => setNewBooking({ ...newBooking, firstName: e.target.value })}
              style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
              required
            />
          </div>
          <div style={{ flex: 1 }}>
            <label style={{ display: 'block', marginBottom: '0.5rem' }}>Prenume</label>
            <input
              type="text"
              value={newBooking.lastName}
              onChange={(e) => setNewBooking({ ...newBooking, lastName: e.target.value })}
              style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
              required
            />
          </div>
        </div>
        <div>
          <label style={{ display: 'block', marginBottom: '0.5rem' }}>Email</label>
          <input
            type="email"
            value={newBooking.email}
            onChange={(e) => setNewBooking({ ...newBooking, email: e.target.value })}
            style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
            required
          />
        </div>
        <div>
          <label style={{ display: 'block', marginBottom: '0.5rem' }}>Telefon</label>
          <input
            type="tel"
            value={newBooking.phone}
            onChange={(e) => setNewBooking({ ...newBooking, phone: e.target.value })}
            style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
            required
          />
        </div>
        <div>
          <label style={{ display: 'block', marginBottom: '0.5rem' }}>Nr. Matriculare</label>
          <input
            type="text"
            value={newBooking.carPlate}
            onChange={(e) => setNewBooking({ ...newBooking, carPlate: e.target.value })}
            style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
            required
          />
        </div>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ flex: 1 }}>
            <label style={{ display: 'block', marginBottom: '0.5rem' }}>Data Început</label>
            <DatePicker
              selected={newBooking.startDate}
              onChange={date => setNewBooking({ ...newBooking, startDate: date })}
              showTimeSelect
              dateFormat="Pp"
              className="form-control"
              style={{ width: '100%', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
              required
            />
          </div>
          <div style={{ flex: 1 }}>
            <label style={{ display: 'block', marginBottom: '0.5rem' }}>Data Sfârșit</label>
            <DatePicker
              selected={newBooking.endDate}
              onChange={date => setNewBooking({ ...newBooking, endDate: date })}
              showTimeSelect
              dateFormat="Pp"
              minDate={newBooking.startDate}
              className="form-control"
              style={{ width: '100%', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
              required
            />
          </div>
        </div>
        <div>
          <label style={{ display: 'block', marginBottom: '0.5rem' }}>Preț (RON)</label>
          <input
            type="number"
            value={newBooking.totalPrice}
            readOnly
            style={{
              width: '100%',
              padding: '0.5rem',
              border: '1px solid #ddd',
              borderRadius: '4px',
              backgroundColor: '#f5f5f5'
            }}
            placeholder="Prețul va fi calculat automat"
          />
        </div>
        <button
          type="submit"
          style={{
            padding: '0.75rem',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '1rem'
          }}
        >
          Create Booking
        </button>
      </form>
    </div>
  );

  // ------------------- Modal pentru Editare Booking -------------------
  const renderEditModal = () => {
    if (!isEditModalOpen) return null;
    
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}>
        <div style={{
          backgroundColor: 'white',
          padding: '2rem',
          borderRadius: '8px',
          width: '90%',
          maxWidth: '600px',
          maxHeight: '90vh',
          overflowY: 'auto'
        }}>
          <h2 style={{ marginBottom: '1.5rem' }}>Editare Rezervare #{editingBooking?.id}</h2>
          
          <form onSubmit={handleEditSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div style={{ flex: 1 }}>
                <label style={{ display: 'block', marginBottom: '0.5rem' }}>Nume</label>
                <input
                  type="text"
                  name="firstName"
                  value={editFormData.firstName}
                  onChange={handleEditFormChange}
                  style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <label style={{ display: 'block', marginBottom: '0.5rem' }}>Prenume</label>
                <input
                  type="text"
                  name="lastName"
                  value={editFormData.lastName}
                  onChange={handleEditFormChange}
                  style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
                />
              </div>
            </div>
            
            <div>
              <label style={{ display: 'block', marginBottom: '0.5rem' }}>Email</label>
              <input
                type="email"
                name="email"
                value={editFormData.email}
                onChange={handleEditFormChange}
                style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
              />
            </div>
            
            <div>
              <label style={{ display: 'block', marginBottom: '0.5rem' }}>Telefon</label>
              <input
                type="tel"
                name="phone"
                value={editFormData.phone}
                onChange={handleEditFormChange}
                style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
              />
            </div>
            
            <div>
              <label style={{ display: 'block', marginBottom: '0.5rem' }}>Nr. Matriculare</label>
              <input
                type="text"
                name="carPlate"
                value={editFormData.carPlate}
                onChange={handleEditFormChange}
                style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
              />
            </div>
            
            <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
              <button
                type="button"
                onClick={handleCloseEditModal}
                style={{
                  flex: 1,
                  padding: '0.75rem',
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}
              >
                Anulează
              </button>
              
              <button
                type="submit"
                style={{
                  flex: 1,
                  padding: '0.75rem',
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}
              >
                Salvează Modificările
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  // ------------------- Endpoint: Incasări Lunare (plată card) -------------------
  const fetchMonthlyData = async () => {
    try {
      const start = new Date("2024-06-01T00:00:00");
      const end = new Date();
      const response = await axios.get('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/card/monthly', {
        params: {
          startDate: start.toISOString(),
          endDate: end.toISOString()
        }
      });
      const months = [];
      let current = new Date(start);
      current.setDate(1);
      const last = new Date(end);
      last.setDate(1);
      while (current <= last) {
        const year = current.getFullYear();
        const month = (current.getMonth() + 1).toString().padStart(2, '0');
        months.push(`${year}-${month}`);
        current.setMonth(current.getMonth() + 1);
      }
      const dataMap = {};
      response.data.forEach(item => {
        dataMap[item.month] = item.totalAmount;
      });
      const finalData = months.map(m => ({
        month: m,
        totalAmount: dataMap[m] || 0
      })).reverse();
      setMonthlyData(finalData);
    } catch (error) {
      console.error("Error fetching monthly breakdown:", error);
    }
  };

  // ------------------- Endpoint: Incasări Lunare (plată cash) -------------------
  const fetchMonthlyCashData = async () => {
    try {
      const start = new Date("2024-06-01T00:00:00");
      const end = new Date();
      const response = await axios.get('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/cash/monthly', {
        params: {
          startDate: start.toISOString(),
          endDate: end.toISOString()
        }
      });
      const months = [];
      let current = new Date(start);
      current.setDate(1);
      const last = new Date(end);
      last.setDate(1);
      while (current <= last) {
        const year = current.getFullYear();
        const month = (current.getMonth() + 1).toString().padStart(2, '0');
        months.push(`${year}-${month}`);
        current.setMonth(current.getMonth() + 1);
      }
      const dataMap = {};
      response.data.forEach(item => {
        dataMap[item.month] = item.totalAmount;
      });
      const finalData = months.map(m => ({
        month: m,
        totalAmount: dataMap[m] || 0
      })).reverse();
      setMonthlyCashData(finalData);
    } catch (error) {
      console.error("Error fetching monthly cash breakdown:", error);
    }
  };

  // ------------------- Endpoint: Incasări Netopia (din endpoint-ul existent "netopia/monthly") -------------------
  const fetchMonthlyNetopiaData = async () => {
    try {
      const start = new Date("2024-06-01T00:00:00");
      const end = new Date();
      const response = await axios.get('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/netopia/monthly', {
        params: {
          startDate: start.toISOString(),
          endDate: end.toISOString()
        }
      });
      setMonthlyNetopiaData(response.data);
    } catch (error) {
      console.error("Error fetching monthly netopia breakdown:", error);
    }
  };

  // ------------------- Endpoint: Incasări Netopia (noul endpoint "netopia_account/monthly") -------------------
  const fetchMonthlyNetopiaAccountData = async () => {
    try {
      const start = new Date("2024-06-01T00:00:00");
      const end = new Date();
      const response = await axios.get('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/netopia_account/monthly', {
        params: {
          startDate: start.toISOString(),
          endDate: end.toISOString()
        }
      });
      setMonthlyNetopiaAccountData(response.data);
    } catch (error) {
      console.error("Error fetching monthly netopia account breakdown:", error);
    }
  };

  useEffect(() => {
    if (activeTab === 'monthlyTotals') {
      fetchMonthlyData();
      fetchMonthlyCashData();
    } else if (activeTab === 'monthlyNetopia') {
      fetchMonthlyNetopiaData();
      fetchMonthlyNetopiaAccountData();
    } else if (activeTab === 'referral') {
      fetchReferralAgencies();
    }
  }, [activeTab]);

  // ------------------- Fetch Referral Agencies -------------------
  const fetchReferralAgencies = async () => {
    setIsLoadingReferralAgencies(true);
    setReferralError(null);
    try {
      const response = await axios.get(`${API_BASE_URL}/bookings/agentie-referral`);
      setReferralAgencies(response.data);
    } catch (error) {
      console.error('Error fetching referral agencies:', error);
      setReferralError('Nu s-au putut încărca agențiile de referral. Vă rugăm să încercați din nou.');
    } finally {
      setIsLoadingReferralAgencies(false);
    }
  };

  // ------------------- Fetch Referral Bookings -------------------
  const fetchReferralBookings = async (referralCode) => {
    setIsLoadingReferral(true);
    setReferralError(null);
    try {
      // First, get all the booking IDs with this referral code
      const referralResponse = await axios.get(`${API_BASE_URL}/bookings/referral-read`);
      const referralData = referralResponse.data.filter(item => item.referralCode === referralCode);
      
      if (referralData.length === 0) {
        setReferralBookings(prevState => ({
          ...prevState,
          [referralCode]: []
        }));
        setIsLoadingReferral(false);
        return;
      }
      
      // Get the relevant booking IDs
      const bookingIds = referralData.map(item => item.bookingId);
      
      // Get full booking details for all the IDs with this referral code
      const bookingsData = await Promise.all(
        bookingIds.map(id => axios.get(`${API_BASE_URL}/bookings/${id}`).catch(err => {
          console.error(`Error fetching booking ID ${id}:`, err);
          return { data: null };
        }))
      );
      
      // Extract the booking objects from the responses, filtering out any null responses
      const bookingsWithReferral = bookingsData
        .filter(response => response.data !== null)
        .map(response => ({
          ...response.data,
          referralCode
        }));
      
      setReferralBookings(prevState => ({
        ...prevState,
        [referralCode]: bookingsWithReferral
      }));
    } catch (error) {
      console.error(`Error fetching referral bookings for code ${referralCode}:`, error);
      setReferralError(`Nu s-au putut încărca rezervările pentru codul de referral "${referralCode}". Vă rugăm să încercați din nou.`);
    } finally {
      setIsLoadingReferral(false);
    }
  };

  // ------------------- Handle Agency Select -------------------
  const handleAgencySelect = async (agency) => {
    setSelectedAgency(agency);
    
    // If we haven't already loaded the bookings for this agency, fetch them
    if (!referralBookings[agency.cod_agentie]) {
      await fetchReferralBookings(agency.cod_agentie);
    }
  };

  const renderCombinedMonthlyBreakdown = () => {
    const combinedData = monthlyData.map(item => {
      const cashItem = monthlyCashData.find(x => x.month === item.month);
      return {
        month: item.month,
        online: item.totalAmount,
        cash: cashItem ? cashItem.totalAmount : 0,
        total: (item.totalAmount || 0) + (cashItem ? cashItem.totalAmount : 0)
      };
    });
    return (
      <div style={{ padding: '1rem 0' }}>
        <h2>Incasări Lunare</h2>
        {combinedData.length === 0 ? (
          <p>Nu există date pentru perioada selectată.</p>
        ) : (
          <table style={{ borderCollapse: 'collapse', width: '100%', backgroundColor: '#fff', border: '1px solid #ddd' }}>
            <thead style={{ backgroundColor: '#f2f2f2' }}>
              <tr>
                <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Luna</th>
                <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Încasat online</th>
                <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Încasat cu plata cash</th>
                <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Total incasat</th>
              </tr>
            </thead>
            <tbody>
              {combinedData.map(item => (
                <tr key={item.month}>
                  <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{item.month}</td>
                  <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>
                    {Number(item.online).toFixed(2)}
                  </td>
                  <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>
                    {Number(item.cash).toFixed(2)}
                  </td>
                  <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>
                    {Number(item.total).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const renderMonthlyNetopiaBreakdown = () => (
    <div style={{ padding: '1rem 0' }}>
      <h2>Incasări Netopia</h2>
      {monthlyNetopiaData.length === 0 ? (
        <p>Nu există date pentru perioada selectată.</p>
      ) : (
        <table style={{ borderCollapse: 'collapse', width: '100%', backgroundColor: '#fff', border: '1px solid #ddd' }}>
          <thead style={{ backgroundColor: '#f2f2f2' }}>
            <tr>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Luna</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Încasat Netopia</th>
              {/* <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Date cont Netopia</th> */}
            </tr>
          </thead>
          <tbody>
            {monthlyNetopiaData.map(item => {
              const accountItem = monthlyNetopiaAccountData.find(x => x.month === item.month);
              return (
                <tr key={item.month}>
                  <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{item.month}</td>
                  <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>
                    {Number(item.totalAmount).toFixed(2)}
                  </td>
                  {/* <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>
                    {accountItem ? Number(accountItem.amount).toFixed(2) : '0.00'}
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );

  // ------------------- Referral View -------------------
  const renderReferralView = () => {
    if (!user || !user.isAdmin) {
      return <div>Nu aveți acces la această secțiune</div>;
    }

    return (
      <div style={{ padding: '1rem 0' }}>
        <h2>Agenții de Referral și Rezervări</h2>
        
        {referralError && !isLoadingReferralAgencies && (
          <div style={{
            backgroundColor: '#fff2f2', 
            color: '#d32f2f', 
            padding: '10px 15px', 
            borderRadius: '4px', 
            marginBottom: '20px',
            border: '1px solid #ffcdd2'
          }}>
            {referralError}
          </div>
        )}
        
        {isLoadingReferralAgencies ? (
          <div style={{ textAlign: 'center', padding: '2rem' }}>
            <p>Se încarcă datele...</p>
          </div>
        ) : referralAgencies.length === 0 ? (
          <p>Nu există agenții de referral înregistrate.</p>
        ) : (
          <div style={{ display: 'flex', gap: '2rem' }}>
            {/* Agencies List Panel */}
            <div style={{ width: '300px', border: '1px solid #ddd', borderRadius: '4px', padding: '1rem', backgroundColor: '#f9f9f9' }}>
              <h3 style={{ marginTop: 0, marginBottom: '1rem' }}>Agenții</h3>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                {referralAgencies.map(agency => (
                  <div
                    key={agency.id}
                    onClick={() => handleAgencySelect(agency)}
                    style={{
                      padding: '0.75rem',
                      backgroundColor: selectedAgency?.id === agency.id ? '#e3f2fd' : 'white',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      transition: 'background-color 0.2s'
                    }}
                  >
                    <div style={{ fontWeight: 'bold' }}>{agency.nume_agentie}</div>
                    <div style={{ fontSize: '0.875rem', color: '#666' }}>Cod: {agency.cod_agentie}</div>
                    <div style={{ fontSize: '0.875rem', color: '#666' }}>Email: {agency.mail_agentie}</div>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Bookings Panel */}
            <div style={{ flex: 1 }}>
              {selectedAgency ? (
                <>
                  <h3>Rezervări pentru {selectedAgency.nume_agentie}</h3>
                  
                  {referralError && !isLoadingReferral && (
                    <div style={{
                      backgroundColor: '#fff2f2', 
                      color: '#d32f2f', 
                      padding: '10px 15px', 
                      borderRadius: '4px', 
                      marginBottom: '20px',
                      border: '1px solid #ffcdd2'
                    }}>
                      {referralError}
                    </div>
                  )}
                  
                  {isLoadingReferral ? (
                    <p>Se încarcă rezervările...</p>
                  ) : referralBookings[selectedAgency.cod_agentie]?.length > 0 ? (
                    <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: 'white', border: '1px solid #ddd' }}>
                      <thead style={{ backgroundColor: '#f2f2f2' }}>
                        <tr>
                          <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>ID</th>
                          <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Nume</th>
                          <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Prenume</th>
                          <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Email</th>
                          <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Telefon</th>
                          <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Matriculare</th>
                          <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Data început</th>
                          <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Data sfârșit</th>
                          <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Preț</th>
                          <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {referralBookings[selectedAgency.cod_agentie].map(booking => (
                          <tr key={booking.id}>
                            <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.id}</td>
                            <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.firstName || 'N/A'}</td>
                            <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.lastName || 'N/A'}</td>
                            <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.email || 'N/A'}</td>
                            <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.phone || 'N/A'}</td>
                            <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.carPlate || 'N/A'}</td>
                            <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                              {booking.startDate ? new Date(booking.startDate).toLocaleString() : 'N/A'}
                            </td>
                            <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                              {booking.endDate ? new Date(booking.endDate).toLocaleString() : 'N/A'}
                            </td>
                            <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                              {typeof booking.totalPrice === 'number' ? booking.totalPrice.toFixed(2) : 'N/A'}
                            </td>
                            <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                              <span style={getStatusStyle(booking.status)}>
                                {booking.status || 'N/A'}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>Nu există rezervări pentru această agenție.</p>
                  )}
                  
                  {/* Commission Calculator */}
                  {referralBookings[selectedAgency.cod_agentie]?.length > 0 && (
                    <div style={{ marginTop: '2rem', backgroundColor: '#e7feea', padding: '1rem', borderRadius: '4px', border: '1px solid #d4edda' }}>
                      <h3 style={{ marginTop: 0 }}>Comision Agenție</h3>
                      <p>
                        <strong>Total Rezervări:</strong> {referralBookings[selectedAgency.cod_agentie].length}
                      </p>
                      <p>
                        <strong>Valoare Totală:</strong> {
                          referralBookings[selectedAgency.cod_agentie]
                            .reduce((sum, booking) => sum + (typeof booking.totalPrice === 'number' ? booking.totalPrice : 0), 0)
                            .toFixed(2)
                        } RON
                      </p>
                      <p>
                        <strong>Comision (15%):</strong> {
                          (referralBookings[selectedAgency.cod_agentie]
                            .reduce((sum, booking) => sum + (typeof booking.totalPrice === 'number' ? booking.totalPrice : 0), 0) * 0.15)
                            .toFixed(2)
                        } RON
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <p>Selectați o agenție pentru a vedea rezervările asociate.</p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  // ------------------- Autentificare -------------------
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (passwordInput === SECRET_KEY) {
      setIsAuthenticated(true);
      setShowEarningsTabs(false);
      setAuthError('');
    } else if (passwordInput === ADVANCED_PASSWORD) {
      setIsAuthenticated(true);
      setShowEarningsTabs(true);
      setAuthError('');
    } else {
      setAuthError('Parolă incorectă. Vă rugăm să încercați din nou.');
    }
  };

  const renderPasswordScreen = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ padding: '2rem', border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '1.5rem' }}>Autentificare</h2>
        <form onSubmit={handlePasswordSubmit}>
          <div style={{ marginBottom: '1rem' }}>
            <label htmlFor="password" style={{ display: 'block', marginBottom: '0.5rem' }}>Parolă:</label>
            <input
              type="password"
              id="password"
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              style={{ width: '100%', padding: '0.5rem', border: authError ? '1px solid red' : '1px solid #ccc', borderRadius: '4px' }}
              required
            />
            {authError && <span style={{ color: 'red', fontSize: '0.875rem' }}>{authError}</span>}
          </div>
          <button
            type="submit"
            style={{
              width: '100%',
              padding: '0.5rem',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Intră
          </button>
        </form>
      </div>
    </div>
  );

  // ------------------- Render Final -------------------
  if (!isAuthenticated) {
    return renderPasswordScreen();
  }

  if (isLoading) return <div style={{ textAlign: 'center', marginTop: '2rem' }}>Loading...</div>;
  if (error) return <div style={{ color: 'red', textAlign: 'center', marginTop: '2rem' }}>Error: {error}</div>;

  return (
    <div className="App" style={{ padding: '1rem' }}>
      <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Booking Management</h1>

      <ul style={{
        display: 'flex',
        listStyle: 'none',
        padding: 0,
        margin: '0 0 2rem 0',
        borderBottom: '1px solid #dee2e6'
      }}>
        <li>
          <button
            onClick={() => setActiveTab('calendar')}
            style={{
              padding: '0.5rem 1rem',
              border: 'none',
              background: 'none',
              borderBottom: activeTab === 'calendar' ? '2px solid #0d6efd' : 'none',
              color: activeTab === 'calendar' ? '#0d6efd' : '#6c757d',
              cursor: 'pointer'
            }}
          >
            Calendar
          </button>
        </li>
        <li>
          <button
            onClick={() => setActiveTab('addOrder')}
            style={{
              padding: '0.5rem 1rem',
              border: 'none',
              background: 'none',
              borderBottom: activeTab === 'addOrder' ? '2px solid #0d6efd' : 'none',
              color: activeTab === 'addOrder' ? '#0d6efd' : '#6c757d',
              cursor: 'pointer'
            }}
          >
            Adaugă Comandă
          </button>
        </li>
        {showEarningsTabs && (
          <>
            <li>
              <button
                onClick={() => setActiveTab('monthlyTotals')}
                style={{
                  padding: '0.5rem 1rem',
                  border: 'none',
                  background: 'none',
                  borderBottom: activeTab === 'monthlyTotals' ? '2px solid #0d6efd' : 'none',
                  color: activeTab === 'monthlyTotals' ? '#0d6efd' : '#6c757d',
                  cursor: 'pointer'
                }}
              >
                Incasări Lunare
              </button>
            </li>
            <li>
              <button
                onClick={() => setActiveTab('monthlyNetopia')}
                style={{
                  padding: '0.5rem 1rem',
                  border: 'none',
                  background: 'none',
                  borderBottom: activeTab === 'monthlyNetopia' ? '2px solid #0d6efd' : 'none',
                  color: activeTab === 'monthlyNetopia' ? '#0d6efd' : '#6c757d',
                  cursor: 'pointer'
                }}
              >
                Incasări Netopia
              </button>
            </li>
            <li>
              <button
                onClick={() => setActiveTab('referral')}
                style={{
                  padding: '0.5rem 1rem',
                  border: 'none',
                  background: 'none',
                  borderBottom: activeTab === 'referral' ? '2px solid #0d6efd' : 'none',
                  color: activeTab === 'referral' ? '#0d6efd' : '#6c757d',
                  cursor: 'pointer'
                }}
              >
                Agenții Referral
              </button>
            </li>
          </>
        )}
      </ul>

      {/* Calendar View */}
      {activeTab === 'calendar' && renderCalendarView()}

      {/* Add Order Form */}
      {activeTab === 'addOrder' && renderAddOrderForm()}

      {/* Monthly Totals */}
      {activeTab === 'monthlyTotals' && renderCombinedMonthlyBreakdown()}

      {/* Netopia Breakdown */}
      {activeTab === 'monthlyNetopia' && renderMonthlyNetopiaBreakdown()}

      {/* Referral View */}
      {activeTab === 'referral' && renderReferralView()}

      {/* Edit Modal */}
      {renderEditModal()}
    </div>
  );
}

export default App;
